%primary-section {
  color: $blue;

  h2 {
    color: $light-pink;
  }

  h3 {
    color: $blue;
  }
}

%secondary-section {
  background-color: $secondary;
  color: $white;

  h2 {
    color: $white;
  }

  h3 {
    color: $light-blue;
  }

  a {
    color: $white;
  }
}

// HEADER
.logo {
  height: 40px;
}

.pt-popover-content {
  width: 12.5rem;
}

.pt-menu.MenuDropDown {
  padding: 0 0.5rem;
  font-size: 1.2rem;

  li {
    padding: 0.4rem 0;
    border-bottom: 1px solid $light-pink;
  }

  li:last-child {
    border-bottom: none;
  }
}

// WELCOME
#welcome {
  background: linear-gradient(
    0deg,
    rgba($blue, 0.6) 0%,
    rgba($blue, 0.6)
    ),
    url(../images/banner.jpg) no-repeat center center / cover;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    color: $white;
    max-width: 550px;
    font-size: 1.7em;
    text-shadow: 0 1px 0 black;
  }
}

// ABOUT
#about {
  @extend %primary-section;
  display: flex;
  align-items: center;

  .content-wrap {
    flex-basis: 65%;
    margin-right: 2rem;
    text-align: left;

    h2 {
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: $laptop) {
    flex-direction: column;

    .content-wrap {
      flex-basis: auto;
      margin: 2rem 0;
    }
  }
}

// EVENTS
#events {
  @extend %secondary-section;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 1rem 0;
  }

  .register {
    width: 100%;
    padding: 1rem 0;

    .button {
      font-weight: 600;
    }

    .button.disabled {
      background-color: $gray;
      color: $white;

      &:hover {
        background-color: $light-gray;
        color: $gray;
      }

      @media (max-width: $tablet) {
        width: 90%;
        max-width: 400px;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .event {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0.3rem 0;
  }

  .date {
    font-weight: 600;
    color: $white;
    min-width: 100px;
    padding-right: 5px;
    text-align: left;
  }

  .text {
    line-height: 1.8rem;
    color: $light-gray;
    width: 90%;

    a {
      color: $light-gray;

      &:hover {
        color: $white;
      }
    }
  }

  .more-events {
    width: 100%;
    padding-bottom: 1rem;

    .show-events-button {
      margin-top: 1rem;
      font-weight: 500;
    }
  }
}

// COMMUNITY
#community {
  @extend %primary-section;

  h2 {
    padding-bottom: 1rem;
    margin: 0;
  }

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
  }

  .carousel-container {
    flex: 1;
    background-color: rgba($light-gray, 0.3);
    border-radius: 0.5rem;
    padding: 1rem 0;
    min-height: 41rem;
    margin-bottom: 1rem;

    img {
      max-width: 22rem;
      height: auto;
      border-radius: 50%;
      margin-bottom: 1rem;

      @media (max-width: $mobile) {
        max-width: 17rem;
      }
    }

    .bio {
      margin: 1rem 2rem;
      font-size: 1.2rem;

      .skills-list {
        list-style: none;
        padding: 0;
      }

      a:hover {
        color: $light-blue;
        transition: 300ms ease-in-out all;
      }

      .social-links {
        padding-top: 1.2rem;
      }

      .social-link {
        padding-right: 1rem;
      }

      .social-link:last-child {
          padding-right: 0;
        }
    }
  }

  .images-container {
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: space-evenly;

    img {
      max-width: 200px;
      height: auto;
      border-radius: 50%;
    }

    .image-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 200px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin: 0.5rem;
    }

    .overlay-text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.25s;
      z-index: 1;
      border-radius: 50%;
      background: linear-gradient(
        0deg,
        rgba($blue, 0.6) 0%,
        rgba($blue, 0.6)
      );
      color: $white;
      font-size: 1.2rem;

      &:hover {
        opacity: 1;
      }
    }

    h2 {
      padding-bottom: 0.6rem;
    }
  }
}

// CODE OF CONDUCT
#code-of-conduct {
  @extend %secondary-section;
  display: flex;
  flex-direction: column;
  align-items: center;

  .padded {
    padding: 10px 0;
  }
}

// modal renders outside of #code-of-conduct
.CodeofConduct.modal {
  width: 90%;
  max-width: 800px;
}

#coc-content {
  padding: 20px;
}

// CONTACT
#contact {
  @extend %primary-section;
  display: flex;
  flex-direction: column;
  align-items: center;

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;

    @media (max-width: $laptop) {
      flex-flow: column wrap;
      align-items: center;
    }
  }

  .contact-method {
    flex: 1;
    margin: 1rem;
    min-width: 200px;
    max-width: 400px;
  }

  .divider {
    margin: auto 0;
    border-right: 2px solid $light-pink;
    height: 120px;

    @media (max-width: $laptop) {
      border-right: none;
      border-bottom: 2px solid $light-pink;
      height: 0;
      width: 35%;
      margin: 0.6rem 0;
    }
  }

  .contact-text {
    padding: 0.6rem 0;
  }
}
