body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
}

section,
footer {
  padding: 4rem;

  @media (max-width: $laptop) {
    padding: 3rem 1rem;
  }
}

a {
  text-decoration: underline;
}

hr {
  border-top: 1px solid $light-pink;
  border-bottom: none;
  max-width: 100px;
  margin: 15px auto;
}

img.responsive {
  max-width: 100%;
  height: auto;
}

.App {
  text-align: center;
}

.bg-pink,
.MenuDropDown.bg-pink {
  background-color: $pink;
}

.white,
.white .pt-icon,
.Menu.white .pt-icon,
.white .pt-button-text {
  color: $white;
}

.blue {
  color: $blue;
}

.light-blue {
  color: $light-blue;
}

.pink {
  color: $pink;
}

.light-pink {
  color: $light-pink;
}

.bold {
  font-weight: 600;
}

.CodeofConduct.button,
.register .button,
.show-events-button.button,
#mc_embed_signup .button,
.button {
  background-color: $light-pink;
  background-image: none;
  box-shadow: none;
  color: $white;
  &:hover {
    background-color: $pink;
  }

  @media (max-width: $tablet) {
    width: 90%;
    max-width: 400px;
  }
}

.border-right {
  border-right: 1px solid $light-pink;
}

/* mailchimp embedded form */
#mc_embed_signup {
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;

  label {
    display: inline-block;
    width: 90%;
    max-width: 400px;
    padding-bottom: 5px;
    text-align: left;
    font-size: 14px;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  input.email {
    width: 90%;
    max-width: 400px;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

#mc-embedded-subscribe.button {
  margin-top: 0.6rem;
}

#mc_hidden {
  position: absolute;
  left: -5000px;
}
/* end mailchimp embedded form */
